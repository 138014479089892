
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import area from '@/utils/area'
import DeliveryArea from '@/components/setting/delivery-area/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { getNonDuplicateID } from '@/utils/util'
import { apiFreightAdd, apiFreightDetail, apiFreightEdit } from '@/api/setting'
@Component({
  components: {
    DeliveryArea,
    LsDialog
  }
})
export default class FreightEdit extends Vue {
    $refs!: { deliveryArea: any; form: any }
    id!: any
    areaLists = area
    form = {
      name: '',
      charge_way: 1,
      type: 1,
      remark: '',
      region: [
        {
          area_id: '',
          region_id: '100000',
          region_name: '全国统一运费',
          first_unit: '',
          first_money: '',
          continue_unit: '',
          continue_money: ''
        }
      ]
    }

    areaId = ''

    get getTableTitle () {
      const { charge_way } = this.form
      switch (charge_way) {
        case 1:
          return '件（件）'
        case 2:
          return '重（Kg）'
        case 3:
          return '体积（m³）'
      }
    }

    get checkRegion () {
      const check = this.form.region.every(item => {
        return (
          item.region_id &&
                item.first_unit !== '' &&
                item.first_unit !== '' &&
                item.first_unit !== '' &&
                item.first_unit !== ''
        )
      })
      if (!check) {
        this.$message.error('填写完整运费模板')
      }
      return check
    }

    handleShowDelivery () {
      this.areaId = getNonDuplicateID()
      this.$refs.deliveryArea.show()
    }

    handleEdit (index: string) {
      this.areaId = index
      this.$refs.deliveryArea.show()
    }

    handleDelete (areaId: string) {
      this.areaId = areaId
      const index = this.form.region.findIndex(item => item.area_id == areaId)
      if (index != -1) {
        this.form.region.splice(index, 1)
      }
      this.$nextTick(() => {
        this.$refs.deliveryArea.clearSelectArea()
      })
    }

    areaChange (value: any[]) {
      const index = this.form.region.findIndex(item => item.area_id == this.areaId)
      const region_id = value.map(item => item.value).join()
      const region_name = value.map(item => item.label).join('、')
      if (index != -1) {
        this.form.region[index].region_id = region_id
        this.form.region[index].region_name = region_name
        return
      }
      this.form.region.push({
        area_id: this.areaId,
        region_id,
        region_name,
        first_unit: '',
        first_money: '',
        continue_unit: '',
        continue_money: ''
      })
    }

    handleSave () {
      this.$refs.form.validate((valid: boolean) => {
        if (valid && this.checkRegion) {
          const api = this.id ? apiFreightEdit(this.form) : apiFreightAdd(this.form)
          api.then(() => {
            this.$router.go(-1)
          })
        } else {
          return false
        }
      })
    }

    getFreightDetail () {
      apiFreightDetail({ id: this.id }).then((res: any) => {
        res.region.forEach((item: any) => {
          item.area_id = getNonDuplicateID()
        })
        this.form = res
        console.log(this.form)
      })
    }

    created () {
      this.id = this.$route.query.id
      this.id && this.getFreightDetail()
    }
}
