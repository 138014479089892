
import area from '@/utils/area'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AreaPanel from './area-panel.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { throttle } from '@/utils/util'
@Component({
    components: {
        AreaPanel,
        LsDialog
    }
})
export default class DeliveryArea extends Vue {
    $refs!: { dialog: any; panelSelect: any; panelAll: any }
    @Prop() areaId!: string
    @Prop() defaultRegion!: any[]
    isFirst = true
    filterText = ''
    areaLists: any[] = JSON.parse(JSON.stringify(area))

    @Watch('defaultRegion')
    defaultRegionChange(val: any[]) {
        if (!this.isFirst) {
            return
        }
        this.isFirst = false
        val.forEach(item => {
            this.setSelectArea(item, this.areaLists)
        })
    }
    show() {
        this.$refs.dialog?.onTrigger()
    }
    onSelect() {
        this.$refs.panelSelect.filter()
    }
    onCancel() {
        this.$refs.panelAll.filter()
    }
    getSelectArea(area: any[]): any[] {
        let newLists: any[] = []
        area.forEach(pitem => {
            let selectTwoArr: any[] = []
            pitem.children &&
                pitem.children.forEach((citem: any) => {
                    let selectThreeArr = citem.children.filter(
                        (ditem: any) => ditem.select && ditem.areaId == this.areaId
                    )
                    if (citem.children.length == selectThreeArr.length) {
                        selectTwoArr.push(citem)
                    } else {
                        newLists.push(...selectThreeArr)
                    }
                })
            if (selectTwoArr.length == pitem.children.length) {
                newLists.push(pitem)
            } else {
                newLists.push(...selectTwoArr)
            }
        })
        return newLists
    }
    clearSelectArea(area?: any[]) {
        area = area || this.areaLists
        for (let i in area) {
            if (area[i].select && area[i].areaId == this.areaId) {
                console.log(this.areaId)
                area[i].select = false
                area[i].areaId = 0
            }
            if (area[i].children) {
                this.clearSelectArea(area[i].children)
            }
        }
    }

    setSelectArea(item: any, area: any[]) {
        area.forEach(pitem => {
            pitem.children &&
                pitem.children.forEach((citem: any) => {
                    citem.children &&
                        citem.children.forEach((ditem: any) => {
                            if (
                                item.region_id.includes(pitem.value) ||
                                item.region_id.includes(citem.value) ||
                                item.region_id.includes(ditem.value)
                            ) {
                                ditem.select = true
                                ditem.areaId = item.area_id
                            }
                        })
                })
        })
    }

    onConfirm = throttle(() => {
        const data = this.getSelectArea(this.areaLists)
        this.$emit('change', data)
    })
    mounted() {
        console.log(this.$refs)
    }
}
